<template>
  <!-- eslint-disable vue/require-v-for-key TODO -->
  <div class="card" style="margin-bottom: 1em">
    <div v-if="displayPopup" class="popover">
      <div class="popover__title">
        {{ popover_title }}
        <Loader
          v-if="$store.getters.isLoading()"
          :width="'15px'"
          :height="'15px'"
        ></Loader>
        <div class="resource__remove close-popover" @click="hidePopup()">
          <span class="icon__close"> </span>
        </div>
      </div>
      <ul class="popover__list">
        <li
          v-for="resource in available_resources"
          class="popover__item"
          @click="selectResource(resource)"
        >
          {{ resource.name }}
        </li>
        <li
          v-if="shouldAddResource()"
          class="popover__item search-assign-new-resource"
          @click="createResource()"
        >
          <span>Add {{ searchTerm }}</span>
        </li>
      </ul>
    </div>

    <div class="card-header">
      {{ title }}
      <Loader
        v-if="$store.getters.isLoading()"
        :width="'20px'"
        :height="'20px'"
      ></Loader>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group col-sm-12">
            <div class="form-group col-sm-12">
              <input
                v-model="searchTerm"
                class="form-control search-assign-input"
                type="text"
                @input="searchResource"
                @focus="showPopup()"
                @keyup.enter="createResource()"
              />
            </div>
            <div class="form-group col-sm-12">
              <ul class="resource_list">
                <li v-for="resource in current_resources" class="resource">
                  <span class="resource__label">{{
                    resource.name ? resource.name : resource
                  }}</span>
                  <div
                    class="resource__remove"
                    @click="removeResource(resource)"
                  >
                    <span class="icon__close"> </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// TODO
/* eslint-disable vue/prop-name-casing */

import Loader from "./loader.vue";
import { debounce } from "lodash";

export default {
  components: {
    Loader,
  },
  props: [
    "current_resources",
    "available_resources",
    "title",
    "popover_title",
    "queryResourceAction",
    "addResourceAction",
    "removeResourceAction",
    "parentResourceId",
    "resourceName",
    "canCreateResource",
  ],
  data() {
    return {
      searchTerm: "",
      displayPopup: false,
    };
  },
  methods: {
    shouldAddResource() {
      return (
        this.canCreateResource &&
        this.available_resources.length == 0 &&
        this.searchTerm.length > 0
      );
    },
    searchResource: debounce(
      function () {
        this.$store.dispatch(
          this.queryResourceAction,
          this.buildPayload(this.searchTerm),
        );
      },
      200,
      { trailing: true },
    ),
    selectResource(resource) {
      if (resource) {
        this.$store.dispatch(
          this.addResourceAction,
          this.buildPayload(resource),
        );
      }
    },
    createResource() {
      if (this.shouldAddResource()) {
        this.$store.dispatch(
          this.addResourceAction,
          this.buildPayload({ name: this.searchTerm }),
        );
      }
      this.searchTerm = "";
      this.searchResource();
    },
    removeResource(resource) {
      this.$store.dispatch(
        this.removeResourceAction,
        this.buildPayload(resource),
      );
    },
    showPopup() {
      this.displayPopup = true;
      this.searchResource();
    },
    hidePopup() {
      this.displayPopup = false;
    },
    buildPayload(resource) {
      const payload = {
        parentResourceId: this.parentResourceId,
      };
      payload[this.resourceName] = resource;
      return payload;
    },
  },
};
</script>

<style scoped>
.resource_list {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}
.resource {
  background-color: #dfe3e8;
  color: #454f5b;
  display: inline-flex;
  word-wrap: break-word;
  max-width: 100%;
  min-height: 24px;
  border-radius: 3px;
  padding: 0px 5px 0px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.resource__label {
  flex: 0 1 auto;
  min-width: 0;
  width: 100%;
  margin-right: 5px;
  display: block;
}
.resource__remove {
  flex-shrink: 0;
  display: block;
  cursor: pointer;
  float: right;
}

.popover {
  position: absolute;
  top: 110px; /* This should anchor it below the text field */
  width: 100%;
  bottom: auto;
  left: 0;
  max-height: 240px;
  overflow: scroll;
  background-color: rgba(255, 255, 255, 0.98);
  -webkit-box-shadow:
    0 0 0 1px rgba(39, 44, 48, 0.05),
    0 2px 7px 1px rgba(39, 44, 48, 0.16);
  box-shadow:
    0 0 0 1px rgba(39, 44, 48, 0.05),
    0 2px 7px 1px rgba(39, 44, 48, 0.16);
  border-radius: 3px;
  max-width: 100%;
  -webkit-transition-property:
    opacity,
    -webkit-transform;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
}
.popover__title {
  display: inline-block;
  color: #798c9c;
  padding: 5px 20px;
  padding-top: 10px;
  font-size: 11px;
  text-transform: uppercase;
  border-bottom: solid 1px #ebeef0;
  margin: 0;
  width: 100%;
}
.popover__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.popover__item {
  padding: 5px 20px;
  border: 0;
  display: block;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  color: #212b36;
  word-wrap: break-word;
  border-radius: 0;
  line-height: 24px;
  cursor: pointer;
}
.popover__item:hover {
  background: #5c6ac4;
  color: white;
}

.popover__new-item {
  background: #5c6ac4;
  color: white;
}
</style>
