import { startCase, camelCase } from "lodash";
import { resourceDisplayAttributes } from "./resource_display_attributes";

export default {
  isLoading: (state) => (resource) => {
    if (resource) {
      return state[resource].loading;
    }

    return state.loading;
  },
  merchantCounts: (state) => {
    return state.merchant_counts;
  },
  tagCounts: (state) => {
    return state.tag_counts;
  },
  countryCounts: (state) => {
    return state.country_counts;
  },
  issueCounts: (state) => {
    return state.issue_counts[state.resource];
  },
  resourceCounts: (state) => {
    return state.resource_counts;
  },
  resources: (state) => (resource) => {
    return state[resource].data;
  },
  resourcePages: (state) => (resource) => {
    return state[resource].pages;
  },
  resourcesTotal: (state) => (resource) => {
    return state[resource].count;
  },
  resourcesCurrentPage: (state) => (resource) => {
    return state[resource].page;
  },
  resourceOrderBy: (state) => (resource) => {
    return state[resource].order_by;
  },
  anyResources: (state) => (resource) => {
    return state[resource].data?.length > 0;
  },
  filter: (state) => (resource) => {
    return state[resource].filter;
  },
  defaultFilter: (state) => {
    return state.defaultFilter;
  },
  getResourceById: (state) => (resource, id) => {
    return state[resource] && state[resource][id];
  },
  resourceRoles: (state) => (resource) => {
    return state[resource].roles;
  },
  merchants: (state) => {
    return state.merchants;
  },
  current_resource: (state) => {
    return state.resource;
  },
  // Example: Merchants
  resourceTypeTitle: (_state) => (resourceType) => {
    return startCase(camelCase(resourceType));
  },
  // Example: Merchant
  resourceTypeTitleSingular: (state) => (resourceType) => {
    return state[resourceType].singular_title;
  },
  currentResourceTypeTitle: (state) => {
    return startCase(camelCase(state.resource));
  },
  resourceAttributes: (_state) => (resource) => {
    return resourceDisplayAttributes[resource];
  },
  showFlash: (state) => {
    return state.flash_message.show;
  },
  showFlashInfo: (state) => {
    return state.flash_message.status == "info";
  },
  showFlashDanger: (state) => {
    return state.flash_message.status == "danger";
  },
  showFlashWarn: (state) => {
    return state.flash_message.status == "warn";
  },
  flashMessage: (state) => {
    return state.flash_message;
  },
  shopifyLocations: (state) => (propertyId) => {
    return state.shopifyLocations[propertyId];
  },
  availableCategories: (state) => {
    return state.available_categories;
  },
  availableLineItemCategories: (state) => {
    return state.available_line_item_categories;
  },
};
