import { Controller } from "@hotwired/stimulus";
import { TelephoneNumberField } from "components/telephone_number_field";

export default class extends Controller {
  static targets = ["form", "submit", "email", "phone"];

  connect() {
    console.log("Review Controller");

    this.phoneInput = new TelephoneNumberField(this.phoneTarget.id);
  }

  submit(event) {
    event.preventDefault();

    if (!this.phoneInput.isValid()) {
      event.stopPropagation();

      this.phoneInput.markAsInvalid();
      alert("Please enter a valid phone number");
      return false;
    }

    this.phoneInput.updateForFormSubmission();

    const form = this.formTarget;
    const formData = new FormData(form);

    fetch(form.action, {
      method: form.method,
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.submitTarget.value = "Sent!";
        } else {
          // Handle errors
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
