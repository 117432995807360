import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "panel"];

  expandAll() {
    this.buttonTargets.forEach((element) => {
      element.ariaExpanded = true;
      element.classList.remove("collapsed");
    });
    this.panelTargets.forEach((element) => {
      element.classList.add("show");
    });
  }

  collapseAll() {
    this.buttonTargets.forEach((element) => {
      element.ariaExpanded = false;
      element.classList.add("collapsed");
    });
    this.panelTargets.forEach((element) => {
      element.classList.remove("show");
    });
  }
}
