import { Controller } from "@hotwired/stimulus";
import { useTransition } from "stimulus-use";

export default class extends Controller {
  static values = {
    transitionDuration: { type: Number, default: 300 },
  };

  connect() {
    useTransition(this, {
      element: this.element,
      enterActive: "fade-enter-active",
      enterFrom: "fade-enter-from",
      enterTo: "fade-enter-to",
      leaveActive: "fade-leave-active",
      leaveFrom: "fade-leave-from",
      leaveTo: "fade-leave-to",
      hiddenClass: false,
      transitioned: true,
    });
  }

  remove() {
    this.leave();
    setTimeout(() => {
      this.element.remove();
    }, this.transitionDurationValue);
  }
}
