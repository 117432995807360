import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "insertBeforeMarker"];

  connect() {
    console.log("template connect");
  }

  add() {
    this.insertBeforeMarkerTarget.insertAdjacentHTML(
      "beforebegin",
      this.templateTarget.innerHTML,
    );
  }
}
