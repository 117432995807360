import {
  checkResponseOk,
  pieApiFetch,
  pieFormFetch,
} from "../../../shared/fetch_decorators";
import { cloneDeep } from "lodash";

export default function (router, resources) {
  return {
    fetchCountryCounts: async ({ commit, dispatch }) => {
      try {
        commit("startLoading");
        const response = await pieApiFetch("/admin/properties/country_count");
        await checkResponseOk(
          response,
          "fetch /admin/properties/country_count failed",
        );
        const data = await response.json();
        commit("updateCountryCounts", data);
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoading");
      }
    },
    setResourceString: ({ commit }, resource) => {
      commit("setResourceString", resource);
    },
    fetchResourceCounts: async ({ commit, dispatch }) => {
      try {
        commit("startLoading");
        const response = await pieApiFetch("/admin/merchants/resource_count");
        await checkResponseOk(
          response,
          "fetch /admin/merchants/resource_count failed",
        );
        const data = await response.json();
        commit("updateResourceCounts", data);
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoading");
      }
    },
    loadFilter: ({ commit }, information) => {
      commit("loadFilter", information);
    },
    clearFilterResourceIds: ({ commit }) => {
      commit("loadFilter", { resource: resources.MERCHANTS });
      commit("loadFilter", { resource: resources.PROPERTIES });
    },
    changePage: ({ commit, dispatch }, information) => {
      commit("changePage", information);
      const query = {
        resource: information.resource,
        endpoint: `${information.resource}`,
      };
      dispatch("query", query);
    },
    query: async ({ commit, state, dispatch }, information) => {
      const resource = (information && information.resource) || state.resource;
      const endpoint = (information && information.endpoint) || state.resource;
      const params = cloneDeep(state[resource].filter);

      params["page"] = state[resource].page;
      params["order_by_attribute"] = state[resource].order_by.attribute;
      params["order_by_direction"] = state[resource].order_by.direction;

      try {
        commit("startLoadingResource", resource);
        const url = `/admin/${endpoint}/query`;
        const response = await pieApiFetch(url, { body: params });
        await checkResponseOk(response, `${url} failed`);
        const data = await response.json();
        commit("updateResource", {
          resource: resource,
          data: data[endpoint],
        });
        commit("updatePages", {
          resource: resource,
          data: data.pages,
        });
        commit("updateCount", {
          resource: resource,
          data: data.total,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resource);
      }
    },
    fetchResource: async ({ commit, dispatch }, information) => {
      try {
        commit("startLoadingResource", information.resource);
        const url = `/admin/${information.resource}/${information.id}`;
        const response = await pieApiFetch(url);
        await checkResponseOk(response, `${url} failed`);
        const data = await response.json();
        console.log(data);
        commit("updateIndividualResource", {
          resource: information.resource,
          data: data,
          id: information.id,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", information.resource);
      }
    },
    updateFilter: ({ commit }, information) => {
      commit("updateFilter", cloneDeep(information));
    },
    updateFilterAndFetch: ({ commit, dispatch, state }, information) => {
      commit("updateFilter", cloneDeep(information));
      if (state[information.resource].page !== 1) {
        commit("changePage", { resource: information.resource, page: 1 });
      }
      dispatch("query", {
        resource: information.resource,
        endpoint: information.endpoint,
      });
    },
    updateResource: async ({ commit, dispatch }, information) => {
      try {
        commit("startLoadingResource", information.resource);
        const url = `/admin/${information.endpoint}`;
        const response = await pieApiFetch(url, {
          method: "PUT",
          body: information.payload,
        });
        await checkResponseOk(response, `${url} failed`);
        const data = await response.json();
        console.log(data);
        commit("updateIndividualResource", {
          resource: information.resource,
          data: data,
          id: information.id,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", information.resource);
      }
    },
    createResource: async ({ commit, dispatch }, information) => {
      try {
        commit("startLoadingResource", information.resource);
        const url = `/admin/${information.endpoint}`;
        console.log(` requesting to ${url}`);
        const response = await pieApiFetch(url, {
          method: "POST",
          body: information.payload,
        });
        await checkResponseOk(response, `${url} failed`);
        const data = await response.json();
        console.log(`got back ${data}`);
        const id = data[information.singularResource].id;
        commit("updateIndividualResource", {
          resource: information.resource,
          data: data[information.singularResource],
          id: id,
        });
        router.push({
          name: `${information.resource}_show`,
          params: { id: id },
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", information.resource);
      }
    },
    resourceAssignment: async ({ commit, dispatch }, information) => {
      try {
        commit("startLoadingResource", information.resource);
        const url = `/admin/${information.endpoint}`;
        console.log(` requesting to ${url}`);
        const response = await pieApiFetch(url, {
          method: "POST",
          body: information.data,
        });
        await checkResponseOk(response, `${url} failed`);
        const data = await response.json();
        commit("updateResource", {
          resource: information.resource,
          data: data[information.resource],
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", information.resource);
      }
    },
    resourceAssignmentSingular: async ({ commit, dispatch }, information) => {
      try {
        commit("startLoading");
        const url = `/admin/${information.endpoint}`;
        const response = await pieApiFetch(url, {
          method: "POST",
          body: information.data,
        });
        await checkResponseOk(response, `${url} failed`);
        const data = await response.json();
        console.log(data);
        commit("updateIndividualResource", {
          resource: information.resource,
          data: data[information.singularResource],
          id: information.id,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoading");
      }
    },
    deletePayoutTier: async ({ commit, dispatch }, information) => {
      try {
        commit("startLoading");
        const url = `/admin/properties/${information.id}/payout_tiers/${information.payout_tier_id}/delete`;
        const response = await pieApiFetch(url, {
          method: "DELETE",
          body: information.data,
        });
        await checkResponseOk(response, `${url} failed`);
        const data = await response.json();
        console.log(data);
        commit("updateIndividualResource", {
          resource: information.resource,
          data: data[information.singularResource],
          id: information.id,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoading");
      }
    },
    clearSearchResults: ({ commit }) => {
      commit("clearSearchResouces");
    },
    closeFlashMessage: ({ commit }) => {
      commit("hideFlash");
    },
    updateOrderBy: ({ commit, dispatch }, payload) => {
      commit("updateOrderBy", payload);
      dispatch("query", {
        resource: payload.resource,
        endpoint: payload.resource,
      });
    },
    resendConfirmation: async ({ commit, dispatch }, payload) => {
      try {
        commit("startLoadingResource", resources.MERCHANTS);
        const url = `/admin/merchants/${payload.id}/resend_confirmation`;
        const response = await pieApiFetch(url, {
          method: "POST",
        });
        await checkResponseOk(response, `${url} failed`);
        commit("setFlashMessage", {
          show: true,
          status: "info",
          message: "Tried to resend the confirmation",
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resources.MERCHANTS);
      }
    },
    sendPasswordResetEmail: async ({ commit, dispatch }, payload) => {
      try {
        commit("startLoadingResource", resources.MERCHANTS);
        const url = "/merchants/password";
        const response = await pieApiFetch(url, {
          method: "POST",
          body: payload,
        });
        if (response.ok) {
          commit("setFlashMessage", {
            show: true,
            status: "info",
            message: "Sent password reset notification.",
          });
        }
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resources.MERCHANTS);
      }
    },
    updateTiers: async ({ commit, dispatch }, payload) => {
      try {
        commit("hideFlash");
        commit("startLoading");
        const response = await pieApiFetch(
          `/admin/properties/${payload.id}/update_payout_tiers`,
          {
            method: "POST",
            body: payload.data,
          },
        );
        await checkResponseOk(response);
        const data = await response.json();
        commit("updateIndividualResource", {
          resource: payload.resource,
          data: data[payload.singularResource],
          id: payload.id,
        });
        commit("setFlashMessage", {
          show: true,
          status: "info",
          message: "The tiers have been updated.",
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoading");
      }
    },
    handleFetchError: ({ commit }, error) => {
      commit("setFlashMessage", {
        show: true,
        status: "danger",
        message: error.message,
      });
    },
    uploadPropertyImage: async ({ commit, dispatch }, payload) => {
      commit("startLoadingResource", resources.PROPERTIES);
      const options = {
        method: "POST",
        body: payload.formData,
      };

      try {
        const response = await pieFormFetch(
          `/properties/${payload.id}/upload_images`,
          options,
        );
        await checkResponseOk(response);

        dispatch("fetchResource", {
          resource: resources.PROPERTIES,
          id: payload.id,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resources.PROPERTIES);
      }
    },
    searchCategories: async ({ commit, dispatch }, payload) => {
      try {
        commit("startLoadingResource", resources.PROPERTIES);
        const url = `/admin/properties/${payload.parentResourceId}/available_categories?query=${payload.category}`;
        const response = await pieApiFetch(url);
        await checkResponseOk(response, `${url} failed`);
        const data = await response.json();
        console.log(data);
        commit("updateAvailableCategories", data);
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resources.PROPERTIES);
      }
    },
    assignCategory: async ({ commit, dispatch }, payload) => {
      console.log(payload);
      commit("startLoadingResource", resources.PROPERTIES);
      const options = {
        method: "POST",
        body: {
          category_id: payload.category.id,
        },
      };

      try {
        const url = `/admin/properties/${payload.parentResourceId}/assign_category`;
        const response = await pieApiFetch(url, options);
        await checkResponseOk(response, `${url} failed`);
        dispatch("fetchResource", {
          resource: resources.PROPERTIES,
          id: payload.parentResourceId,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resources.PROPERTIES);
      }
    },

    removeCategory: async ({ commit, dispatch }, payload) => {
      console.log(payload);
      commit("startLoadingResource", resources.PROPERTIES);
      const options = {
        method: "DELETE",
        body: {
          category_id: payload.category.id,
        },
      };

      try {
        const url = `/admin/properties/${payload.parentResourceId}/remove_category`;
        const response = await pieApiFetch(url, options);
        await checkResponseOk(response, `${url} failed`);
        dispatch("fetchResource", {
          resource: resources.PROPERTIES,
          id: payload.parentResourceId,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resources.PROPERTIES);
      }
    },

    createLineItemCategory: async ({ commit, dispatch }, payload) => {
      console.log(payload);
      commit("startLoadingResource", resources.PROPERTIES);
      const options = {
        method: "POST",
        body: {
          name: payload.line_item_category.name,
        },
      };

      try {
        const url = `/properties/${payload.parentResourceId}/line_item_categories`;
        const response = await pieApiFetch(url, options);
        await checkResponseOk(response, `${url} failed`);
        dispatch("fetchResource", {
          resource: resources.PROPERTIES,
          id: payload.parentResourceId,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resources.PROPERTIES);
      }
    },

    destroyLineItemCategory: async ({ commit, dispatch }, payload) => {
      console.log(payload);
      commit("startLoadingResource", resources.PROPERTIES);
      const options = {
        method: "DELETE",
        body: {
          id: payload.id,
        },
      };

      try {
        const url = `/line_item_categories/${payload.line_item_category.id}`;
        const response = await pieApiFetch(url, options);
        await checkResponseOk(response, `${url} failed`);
        dispatch("fetchResource", {
          resource: resources.PROPERTIES,
          id: payload.parentResourceId,
        });
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resources.PROPERTIES);
      }
    },

    searchLineItemCategories: async ({ commit, dispatch }, payload) => {
      console.log(payload);
      try {
        commit("startLoadingResource", resources.PROPERTIES);
        const url = `/properties/${payload.parentResourceId}/line_item_categories?query=${payload.line_item_category}`;
        const response = await pieApiFetch(url);
        await checkResponseOk(response, `${url} failed`);
        const data = await response.json();
        console.log(data);
        commit("updateAvailableLineItemCategories", data);
      } catch (error) {
        dispatch("handleFetchError", error);
      } finally {
        commit("finishLoadingResource", resources.PROPERTIES);
      }
    },
  };
}
