<template>
  <!-- eslint-disable max-len -->
  <div class="show">
    <div class="row justify-content-between align-items-center">
      <div class="col-9">
        <h1>
          Property
          <span v-if="property.test_property" class="badge badge-warning"
            >TEST</span
          >
          <span
            v-if="property.hybrid_flow_enabled"
            class="badge badge-secondary"
            >HybridFlow 🛃</span
          >
        </h1>
      </div>
      <div class="col-2">
        <form action="/orders" method="POST">
          <input
            type="hidden"
            name="authenticity_token"
            autocomplete="off"
            :value="csrfToken"
          />
          <input type="hidden" name="order[property_id]" :value="property.id" />
          <button type="submit" class="btn btn-primary">New Order</button>
        </form>
      </div>
      <div class="col-1">
        <router-link :to="{ path: '/' }">Back</router-link>
      </div>
    </div>
    <Flash />
    <div>
      <form @submit.prevent="onPropertySubmit">
        <div class="card" style="margin-bottom: 1em">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="btn-toolbar justify-content-between" role="toolbar">
                  <h3>Address</h3>
                  <div class="input-group">
                    <button
                      v-if="!isEditing"
                      type="button"
                      class="btn btn-primary"
                      @click="startEditing()"
                    >
                      Edit
                    </button>
                    <button
                      v-if="isEditing"
                      type="button"
                      class="btn btn-primary"
                      @click="cancelEditing()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-6 col-sm-12">
                <div class="form-group col-md-12">
                  <label name="address_1">Address 1</label>
                  <input
                    v-model="property.address_attributes.address_1"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label name="address_2">Address 2</label>
                  <input
                    v-model="property.address_attributes.address_2"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label name="name">City</label>
                  <input
                    v-model="property.address_attributes.city"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label name="latlon">Lat Lon</label>
                  <input
                    v-model="property.address_attributes.latlon"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                    placeholder="55.67122172293022,12.585612203489024"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="form-group col-md-12">
                  <label name="province">Province</label>
                  <input
                    v-model="property.address_attributes.province"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label name="postal_code">Postal Code</label>
                  <input
                    v-model="property.address_attributes.postal_code"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>
                <SearchSuggestInput
                  id="property-country-selector"
                  :label="'Country (required)'"
                  :popover_title="'Found Countries'"
                  :results-string="resourceTypes.SEARCHED_RESOURCE"
                  :resource-string="resourceTypes.COUNTRIES"
                  :available_resources="
                    resources(resourceTypes.SEARCHED_RESOURCE)
                  "
                  :disable-input="!isEditing"
                  :default-value="
                    property.address_attributes.country &&
                    property.address_attributes.country.name
                  "
                  :resource-attribute="'id'"
                  :select-method="updateCountry"
                  :required="true"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-6 col-sm-12">
                <button
                  v-if="isEditing && !newProperty"
                  type="submit"
                  class="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card" style="margin-bottom: 1em">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h3>Details</h3>
                <div class="btn-toolbar justify-content-between" role="toolbar">
                  <div class="btn-group" role="group">
                    <a
                      v-if="
                        property.integration_type &&
                        property.integration_type.length > 0
                      "
                      type="button"
                      class="btn btn-outline-primary rounded-0"
                      :href="`/admin/properties/${property.id}/integrations/new`"
                    >
                      {{ integrationLabel }}
                      <span
                        v-if="property.is_integration_active"
                        class="badge badge-success"
                        >active</span
                      >
                    </a>
                    <!-- rounded-0 removes an inconsistent border radius and fixes
                        screenshot test flakiness -->
                    <button
                      v-if="!newProperty"
                      type="button"
                      class="btn btn-outline-primary rounded-0"
                      data-toggle="modal"
                      data-target="#payout-tiers"
                    >
                      Payout Tiers
                    </button>
                    <a
                      v-if="property.economic_account_id"
                      id="edit-economic-account"
                      type="button"
                      class="btn btn-link"
                      :href="`/economic_accounts/${property.economic_account_id}/edit`"
                    >
                      Edit E-conomic Account
                    </a>
                    <a
                      v-if="!property.economic_account_id && property.id"
                      id="create-economic-account"
                      type="button"
                      class="btn btn-link"
                      :href="`/economic_accounts/new?property_id=${property.id}`"
                    >
                      Create New E-conomic Account
                    </a>

                    <a
                      v-if="property.id"
                      id="qr-code"
                      type="button"
                      class="btn btn-link"
                      :href="`/properties/${property.id}/qr_code`"
                    >
                      QR Code
                    </a>

                    <Loader
                      v-if="$store.getters.isLoading(resourceTypes.PROPERTIES)"
                      :width="'25px'"
                      :height="'25px'"
                    />
                  </div>
                  <div class="input-group">
                    <button
                      v-if="!isEditing"
                      id="edit-button"
                      type="button"
                      class="btn btn-primary"
                      @click="startEditing()"
                    >
                      Edit
                    </button>
                    <button
                      v-if="isEditing"
                      type="button"
                      class="btn btn-primary"
                      @click="cancelEditing()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-6 col-sm-12">
                <div class="form-group col-md-12">
                  <label name="name">Name (required)</label>
                  <input
                    id="property-name"
                    v-model="property.name"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group col-md-12">
                  <label name="short-code">Short Code</label>
                  <input
                    id="property-short-code"
                    v-model="property.short_code"
                    type="text"
                    disabled="true"
                    class="form-control test-skip-area"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label name="id">ID</label>
                  <input
                    v-model="property.id"
                    type="text"
                    disabled="true"
                    class="form-control test-skip-area"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label name="property_number">Property Number</label>
                  <input
                    v-model="property.property_number"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label name="tier">Tier</label>
                  <select
                    id="property-tier-select"
                    v-model="property.tier"
                    :disabled="!isEditing"
                    class="form-control"
                  >
                    <!-- eslint-disable-next-line vue/require-v-for-key TODO -->
                    <option v-for="value in [null, 1, 2, 3, 4, 5]">
                      {{ value }}
                    </option>
                  </select>
                </div>

                <div class="form-group col-md-12">
                  <label>Point of Sale System</label>
                  <div class="form-check">
                    <input
                      id="no-pos-button"
                      v-model="property.pos_system"
                      class="form-check-input"
                      type="radio"
                      name="pos_system"
                      value="undefined"
                      :disabled="!isEditing"
                    />
                    <label class="form-check-label" for="no-pos-button">
                      None
                    </label>
                  </div>
                  <div
                    v-for="system in posSystems"
                    :key="system.id"
                    class="form-check"
                  >
                    <input
                      :id="posButtonId(system)"
                      v-model="property.pos_system"
                      class="form-check-input"
                      type="radio"
                      name="pos_system"
                      :value="system.id"
                      :disabled="!isEditing"
                    />
                    <label class="form-check-label" :for="posButtonId(system)">
                      {{ system.name }}
                    </label>
                  </div>
                </div>
                <div
                  v-if="property.integrated_with_shopify"
                  class="form-group col-md-12"
                >
                  <FilterSelectInput
                    :input-id="'shopify-location-id-input'"
                    :label="'Shopify Location'"
                    :popover-title="'Shopify Locations'"
                    :available-resources="property.shopify_locations"
                    :disable-input="!isEditing"
                    :default-value="property.shopify_location_id"
                    :resource-attribute="'id'"
                    :select-method="updateShopifyLocationId"
                  />
                </div>
                <div
                  v-if="property.integrated_with_sitoo"
                  class="form-group col-md-12"
                >
                  <FilterSelectInput
                    :input-id="'sitoo-store-id-input'"
                    :label="'Sitoo Store'"
                    :popover-title="'Sitoo Stores'"
                    :available-resources="property.sitoo_stores"
                    :disable-input="!isEditing"
                    :default-value="property.integration.sitoo_store_name"
                    :resource-attribute="'id'"
                    :select-method="updateSitooStoreId"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-sm-12">
                <div class="form-group col-md-12">
                  <label name="default_tax_percentage"
                    >Standard VAT Rate (DK, SE, NO: 25, FI: 25.5, DE: 19, JP:
                    10, NL: 21) (required)</label
                  >
                  <input
                    id="property-default-tax-percentage-input"
                    v-model="property.default_tax_percentage"
                    type="number"
                    step="0.1"
                    :disabled="!isEditing"
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group col-md-12">
                  <label name="minimum_order_value"
                    >Minimum Order Value to qualify for VAT * (e.g. Denmark is
                    300.01)</label
                  >
                  <input
                    v-model="property.minimum_order_value"
                    type="number"
                    step="0.01"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label name="start_date">Start Date</label>
                  <input
                    v-model="property.start_date"
                    type="date"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label name="churned_at"> Churned On </label>
                  <input
                    v-model="property.churned_at"
                    type="date"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label name="typeform_form_id">Typeform Form ID</label>
                  <input
                    v-model="property.typeform_form_id"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label name="hubspot_id">Hubspot ID</label>
                  <input
                    v-model="property.hubspot_id"
                    type="text"
                    :disabled="!isEditing"
                    class="form-control"
                  />
                </div>

                <div class="form-group col-md-12">
                  <label name="proof_flow_type">Proof Flow</label>
                  <select
                    id="property-proof-flow-type-select"
                    v-model="property.proof_flow_type"
                    :disabled="!isEditing"
                    class="form-control"
                  >
                    <!-- eslint-disable-next-line vue/require-v-for-key TODO -->
                    <option
                      v-for="value in [
                        ['digital_flow', 'Digital Flow'],
                        ['hybrid_flow', 'Hybrid Flow'],
                        ['jp_self_service_flow', 'JP Self Service'],
                      ]"
                      :value="value[0]"
                    >
                      {{ value[1] }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <div class="form-check">
                    <input
                      v-model="property.display_in_app"
                      type="checkbox"
                      :disabled="!isEditing"
                      class="form-check-input"
                    />
                    <label name="display_in_app" class="form-check-label"
                      >Display In App</label
                    >
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <div class="form-check">
                    <input
                      v-model="property.test_property"
                      type="checkbox"
                      :disabled="!isEditing"
                      class="form-check-input"
                    />
                    <label name="test_property" class="form-check-label"
                      >Test Property</label
                    >
                  </div>
                </div>

                <div class="form-group col-md-12">
                  <div class="form-check">
                    <input
                      id="charges_pie_transfer_fee"
                      v-model="property.charges_pie_transfer_fee"
                      type="checkbox"
                      disabled="true"
                      class="form-check-input"
                    />
                    <label
                      name="charges_pie_transfer_fee"
                      for="charges_pie_transfer_fee"
                      class="form-check-label"
                    >
                      Charge Pie Transfer Fee on Withdraw</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-6 col-sm-12">
                <button
                  v-if="
                    property.integration_type === 'sitoo' &&
                    property.is_integration_active
                  "
                  type="button"
                  class="btn btn-primary"
                  @click="copySitooWebAppConfigYaml"
                >
                  Copy Sitoo web app config to clipboard
                </button>

                <a
                  v-if="
                    property.integration_type === 'sitoo' &&
                    property.is_integration_active
                  "
                  type="button"
                  class="btn btn-link"
                  :href="`${property.integration.pos_authentication_url}`"
                  target="_blank"
                  >POS Authentication URL</a
                >

                <button
                  v-if="isEditing && !newProperty"
                  type="submit"
                  class="btn btn-primary save"
                >
                  Save
                </button>
                <button
                  v-if="isEditing && newProperty"
                  id="create-property-button"
                  type="submit"
                  class="btn btn-primary"
                >
                  Create New Property
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card" style="margin-bottom: 1em">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h3>For Japan</h3>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-lg-6 col-12">
                <label name="jp-nta-shop-name"> NTA Shop Name </label>
                <input
                  id="property-jp-nta-shop-name"
                  v-model="property.jp_nta_shop_name"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>
              <div class="form-group col-lg-6 col-md-12">
                <label name="nta-shop-id">NTA Shop ID</label>
                <input
                  v-model="property.jp_nta_shop_id"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>
              <div class="form-group col-lg-6 col-md-12">
                <label name="nta-corporate-name"
                  >NTA Business Name (Corporate)</label
                >
                <input
                  v-model="property.jp_nta_corporate_name"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>
              <div class="form-group col-lg-6 col-md-12">
                <label name="nta-corporate-address"
                  >NTA Business Address (Corporate)</label
                >
                <input
                  v-model="property.jp_nta_corporate_address"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>
              <div class="form-group col-lg-6 col-12">
                <label name="default_shipping_company_name">
                  Default Shipping Company Name
                </label>
                <input
                  id="property-default-shipping-company-name-input"
                  v-model="property.default_shipping_company_name"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>
              <div class="form-group col-lg-6 col-12">
                <label name="line_item_categories_link">
                  Line Item Categories Link
                </label>
                <input
                  id="property-line-item-categories-link-input"
                  v-model="property.line_item_categories_link"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                  :placeholder="'A complete URL https://www.google.com'"
                />
              </div>
              <div class="form-group col-lg-6 col-12">
                <label name="affiliate_id"> Affiliate Id </label>
                <input
                  id="property-affiliate-id-input"
                  v-model="property.affiliate_id"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                  :placeholder="'An arbitrary id to group properties for Metabase'"
                />
              </div>
              <div v-if="!newProperty" class="col-lg-6 col-12">
                <SearchAndAssign
                  id="line-item-categories"
                  :current_resources="property.line_item_categories"
                  :title="'Line Item Categories'"
                  :popover_title="'Line Item Categories'"
                  :available_resources="availableLineItemCategories"
                  :query-resource-action="'searchLineItemCategories'"
                  :add-resource-action="'createLineItemCategory'"
                  :remove-resource-action="'destroyLineItemCategory'"
                  :resource-name="'line_item_category'"
                  :parent-resource-id="property.id"
                  :can-create-resource="true"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col-lg-6 col-sm-12">
                <button
                  v-if="isEditing && !newProperty"
                  type="submit"
                  class="btn btn-primary"
                >
                  Save
                </button>
                <button
                  v-if="isEditing && newProperty"
                  type="submit"
                  class="btn btn-primary"
                >
                  Create New Property
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card" style="margin-bottom: 1em">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="btn-toolbar justify-content-between" role="toolbar">
                  <h3>Store Profile</h3>
                  <div class="input-group">
                    <button
                      v-if="!isEditing"
                      type="button"
                      class="btn btn-primary"
                      @click="startEditing()"
                    >
                      Edit
                    </button>
                    <button
                      v-if="isEditing"
                      type="button"
                      class="btn btn-primary"
                      @click="cancelEditing()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-lg-6 col-md-12">
                <label name="description">Description</label>
                <input
                  v-model="property.description"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label name="store-website">Website</label>
                <input
                  v-model="property.website"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label name="phone-number">Phone Number</label>
                <input
                  v-model="property.phone_number"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>
              <div class="form-group col-lg-6 col-md-12">
                <label name="instagram">Instagram</label>
                <input
                  v-model="property.instagram"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>
              <div class="form-group col-lg-6 col-md-12">
                <label name="facebook">Facebook</label>
                <input
                  v-model="property.facebook"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>
              <div class="form-group col-lg-6 col-md-12">
                <label name="store-hours">Operating Hours</label>
                <input
                  v-model="property.operating_hours"
                  type="text"
                  :disabled="!isEditing"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-row">
              <SearchSuggestInput
                :label="'Primary Category'"
                :popover_title="'Available Categories'"
                :results-string="resourceTypes.SEARCHED_RESOURCE"
                :resource-string="resourceTypes.CATEGORIES"
                :available_resources="
                  resources(resourceTypes.SEARCHED_RESOURCE)
                "
                :disable-input="!isEditing"
                :default-value="property.category && property.category.name"
                :resource-attribute="'id'"
                :select-method="updateCategory"
              />

              <div v-if="!newProperty" class="col-lg-6 col-12">
                <SearchAndAssign
                  id="categories"
                  :current_resources="property.categories"
                  :available_resources="availableCategories"
                  :title="'Secondary Categories'"
                  :popover_title="'Available Categories'"
                  :query-resource-action="'searchCategories'"
                  :add-resource-action="'assignCategory'"
                  :remove-resource-action="'removeCategory'"
                  :resource-name="'category'"
                  :parent-resource-id="property.id"
                  :can-create-resource="false"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="col-lg-6 col-sm-12">
                <button
                  v-if="isEditing && !newProperty"
                  type="submit"
                  class="btn btn-primary"
                >
                  Save
                </button>
                <button
                  v-if="isEditing && newProperty"
                  type="submit"
                  class="btn btn-primary"
                >
                  Create New Property
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card" style="margin-bottom: 1em">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h3>Images</h3>

                <Loader
                  v-if="$store.getters.isLoading(resourceTypes.PROPERTIES)"
                  :width="'25px'"
                  :height="'25px'"
                />
              </div>
            </div>

            <div class="row">
              <div col="col-6">
                <div class="form-group col-12">
                  <label name="store-hours">Logo Image (128x128 px)</label>
                  <div class="custom-file">
                    <input
                      name="logo_image"
                      type="file"
                      class="custom-file-input"
                      @change="handleFiles"
                    />
                    <label class="custom-file-label" for="logo_image"
                      >{{
                        (property.logo_image && property.logo_image.name) ||
                        "Logo Image"
                      }}
                    </label>
                    <div class="invalid-feedback"></div>
                  </div>
                </div>
                <div class="form-group col-12">
                  <label name="store-hours">Hero Image (800w x 400h px)</label>
                  <div class="custom-file">
                    <input
                      name="hero_image"
                      type="file"
                      class="custom-file-input"
                      @change="handleFiles"
                    />
                    <label class="custom-file-label" for="hero_image"
                      >{{
                        (property.hero_image && property.hero_image.name) ||
                        "Hero Image"
                      }}
                    </label>
                    <div class="invalid-feedback"></div>
                  </div>
                </div>
              </div>
              <div col="col-6">
                <img
                  class="rounded mx-auto"
                  style="max-width: 64px; height: auto"
                  :src="property.logo_image && property.logo_image.url"
                />
                <img
                  class="rounded mx-auto"
                  style="max-width: 200px; height: auto"
                  :src="property.hero_image && property.hero_image.url"
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      <div v-if="!newProperty" class="row">
        <div class="col-12">
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#assign-merchants"
          >
            Manage Merchant Account Access
          </button>
          <a
            :href="`/admin/properties/${property.id}/competitions`"
            class="btn btn-primary"
          >
            Competitions
          </a>
          <a
            :href="`/admin/properties/${property.id}/offers`"
            class="btn btn-primary"
          >
            Offers
          </a>

          <SearchAndAssignTable
            id="assign-merchants"
            :search-resource-attributes="
              resourceAttributes(resourceTypes.MERCHANTS)
            "
            :search-resource-type="resourceTypes.MERCHANTS"
            :current-resource-id="property.id"
            :current-resource-type="resourceTypes.PROPERTIES"
            :change-page="changePageResource"
          />
        </div>
      </div>

      <VueTable
        v-if="
          $store.getters.anyResources(resourceTypes.MERCHANTS) && !newProperty
        "
        :title="'Merchants'"
        :attributes="resourceAttributes(resourceTypes.MERCHANTS)"
        :items="resources(resourceTypes.MERCHANTS)"
        :pages="resourcePages(resourceTypes.MERCHANTS)"
        :total="resourcesTotal(resourceTypes.MERCHANTS)"
        :current-page="resourcesCurrentPage(resourceTypes.MERCHANTS)"
        :order-by="resourceOrderBy(resourceTypes.MERCHANTS)"
        :resource-string="resourceTypes.MERCHANTS"
        :change-page="changePage"
      />
    </div>

    <PayoutTiers
      :payout-tiers="property.payout_tiers"
      :property-id="property.id"
    />
  </div>
</template>

<script>
import Flash from "components/flash_message.vue";
import Loader from "components/loader.vue";
import PayoutTiers from "components/payout_tiers.vue";
import Resources from "admin/resources.js";
import SearchAndAssignTable from "components/search_and_assign_table.vue";
import SearchAndAssign from "components/search_and_assign.vue";
import SearchSuggestInput from "components/search_suggest_input.vue";
import FilterSelectInput from "components/filter_select_input.vue";
import VueTable from "components/table.vue";

import { map, capitalize } from "lodash";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    Loader,
    VueTable,
    SearchAndAssignTable,
    SearchAndAssign,
    SearchSuggestInput,
    FilterSelectInput,
    PayoutTiers,
    Flash,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.newProperty = to.name == "properties_new";
    });
  },
  data() {
    return {
      property: {
        currency: { name: "" },
        address_attributes: { country: {} },
        category: {},
      },
      resourceTypes: Resources,
      isEditing: this.$route.params.id ? false : true,
      newProperty: this.$route.name == "properties_new",
    };
  },
  computed: {
    csrfToken() {
      return document
        .querySelector('meta[name="csrf-token"]')
        ?.getAttribute("content");
    },
    storedProperty() {
      return this.$store.getters.getResourceById(
        Resources.PROPERTIES,
        this.$route.params.id,
      );
    },
    integrationLabel() {
      return map(this.property.integration_type?.split("_"), function (str) {
        return capitalize(str);
      }).join(" ");
    },
    ...mapState(["posSystems"]),
    ...mapGetters([
      "resourceAttributes",
      "resourceOrderBy",
      "resourcePages",
      "resources",
      "resourcesCurrentPage",
      "resourcesTotal",
      "availableCategories",
      "availableLineItemCategories",
    ]),
  },
  watch: {
    storedProperty: {
      handler: function (newData, _oldData) {
        this.property = newData;
      },
      deep: true,
    },
  },
  beforeMount() {
    if (this.$route.params.id) {
      return this.$store
        .dispatch("fetchResource", {
          resource: Resources.PROPERTIES,
          id: this.$route.params.id,
        })
        .then(() => {
          return this.$store.dispatch("updateFilterAndFetch", {
            resource: Resources.MERCHANTS,
            endpoint: Resources.MERCHANTS,
            filter: { property_id: this.$route.params.id },
          });
        });
    }
  },
  methods: {
    changePage(page) {
      this.$store.dispatch("changePage", {
        resource: Resources.MERCHANTS,
        page: page,
      });
    },
    changePageResource(page, resource) {
      this.$store.dispatch("changePage", { resource: resource, page: page });
    },
    startEditing() {
      this.isEditing = true;
    },
    cancelEditing() {
      this.isEditing = false;
    },
    copySitooWebAppConfigYaml(event) {
      navigator.clipboard
        .writeText(this.property.integration["sitoo_web_app_config_yaml"])
        .then(() => {
          event.target.textContent = "Copied!";
          event.target.disabled = true;
        });
    },
    onPropertySubmit() {
      if (this.newProperty) {
        this.createResource();
      } else {
        this.saveResource();
      }
    },
    saveResource() {
      this.isEditing = false;
      this.$store.dispatch("updateResource", {
        endpoint: `${Resources.PROPERTIES}/${this.property.id}`,
        resource: Resources.PROPERTIES,
        payload: { property: this.property },
      });
    },
    createResource() {
      this.isEditing = false;
      this.$store.dispatch("createResource", {
        endpoint: `${Resources.PROPERTIES}`,
        resource: "properties",
        singularResource: "property",
        payload: { property: this.property },
      });
    },
    updateCategory(newCategory) {
      this.property.category_id = newCategory.id;
    },
    updateCountry(newCountry) {
      this.property.address_attributes.country_id = newCountry.id;
    },
    updateShopifyLocationId(newLocationId) {
      this.property.shopify_location_id = newLocationId.id;
    },
    updateSitooStoreId(newStoreId) {
      this.property.integration_attributes.sitoo_store_id = newStoreId.id;
    },
    posButtonId(posSystem) {
      return posSystem.id + "-pos-button";
    },
    handleFiles(event) {
      event.preventDefault();
      const el = event.target;
      const fileList = el.files;
      const errorField = el.parentNode.querySelector(".invalid-feedback");
      const label = el.parentNode.querySelector("label");
      console.log("added", fileList);

      const formData = new FormData();
      const file = fileList[0];
      label.innerText = file.name;
      // Less than 600kb
      if (file.size && file.size / 1024 < 600) {
        formData.append(el.name, file, file.name);
        this.$store.dispatch("uploadPropertyImage", {
          id: this.$route.params.id,
          formData: formData,
        });
      } else {
        el.classList.add("is-invalid");
        errorField.innerText =
          "The file is too large, please try downsizing with https://squoosh.app/";
      }
    },
  },
};
</script>
