export default function (vue) {
  return {
    startLoading(state) {
      state.loading = true;
    },
    finishLoading(state) {
      state.loading = false;
    },
    startLoadingResource(state, resource) {
      vue.set(state[resource], "loading", true);
    },
    finishLoadingResource(state, resource) {
      vue.set(state[resource], "loading", false);
    },
    loadFilter(state, information) {
      let filter_key = `__pie__admin_${information.resource}_filter`;
      if (information.id) {
        filter_key = `__pie__admin_${information.resource}_filter_${information.id}`;
      }
      const jsonFilter = localStorage.getItem(filter_key);

      if (jsonFilter && jsonFilter !== "undefined") {
        const parsed = JSON.parse(jsonFilter);
        delete parsed.property_id;
        delete parsed.merchant_id;
        vue.set(state[information.resource], "filter", parsed);
      }
    },
    updateFilter(state, information) {
      let filter_key = `__pie__admin_${information.resource}_filter`;
      if (information.id) {
        filter_key = `__pie__admin_${information.resource}_filter_${information.id}`;
      }

      if (information.resource) {
        vue.set(state[information.resource], "filter", information.filter);
      } else {
        vue.set(state, "filter", information.filter);
      }

      const parsed = JSON.stringify(information.filter);
      localStorage.setItem(filter_key, parsed);
    },
    updateCountryCounts(state, counts) {
      vue.set(state, "country_counts", counts);
    },
    updateResourceCounts(state, counts) {
      vue.set(state, "resource_counts", counts);
    },
    setResourceString(state, string) {
      vue.set(state, "resource", string);
    },
    updateResource(state, information) {
      vue.set(state[information.resource], "data", information.data);
      // TODO(alan): Store by id in state[resourceType][byId]
      for (const resource of information.data) {
        vue.set(state[information.resource], resource.id, resource);
      }
    },
    addResources(state, { type, resources }) {
      for (const resource of resources) {
        vue.set(state[type], resource.id, resource);
      }
    },
    updatePages(state, information) {
      state[information.resource].pages = information.data;
    },
    updateCount(state, information) {
      state[information.resource].count = information.data;
    },
    updateCurrentPage(state, information) {
      state[information.resource].page = information.data;
    },
    updateIndividualResource(state, information) {
      vue.set(state[information.resource], information.id, information.data);
      const errorMessages = information.data.error_messages;
      if (errorMessages) {
        state.flash_message.show = true;
        state.flash_message.status = "danger";
        state.flash_message.message = errorMessages.join("\n");
      }
    },
    changePage(state, information) {
      state[information.resource].page = information.page;
    },
    clearSearchResouces(state) {
      vue.set(state["searched_resource"], "data", []);
    },
    hideFlash(state) {
      vue.set(state["flash_message"], "show", false);
    },
    showFlash(state) {
      vue.set(state["flash_message"], "show", true);
    },
    setFlashMessage(state, payload) {
      vue.set(state, "flash_message", payload);
    },
    updateOrderBy(state, payload) {
      vue.set(state[payload.resource], "order_by", payload.orderBy);
    },
    updateShopifyLocations(state, payload) {
      vue.set(state["shopifyLocations"], payload.propertyId, payload.data);
    },
    updateAvailableCategories(state, data) {
      vue.set(state, "available_categories", data.categories);
    },
    updateAvailableLineItemCategories(state, data) {
      vue.set(
        state,
        "available_line_item_categories",
        data.line_item_categories,
      );
    },
  };
}
